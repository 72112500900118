/*------------------------------------
  Clients
------------------------------------*/
.u-clients {
  width: 100%;
  // max-width: $clients-width;
  max-width: 170px;
  max-height: 70px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}
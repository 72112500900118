.page--content {

}

// Items
.page--content__item {
  // Flex on sm and above
  @include media-breakpoint-up(md) {
    display: flex;
  }

  // Even/odd on md and above
  @include media-breakpoint-up(md) {
    &:nth-child(even) {
      // Title
      .page--content__item__title {
        order: 2;
        text-align: right;
        padding-right: 0;
        padding-left: 2rem;
      }

      // Content
      .page--content__item__content {
        order: 1;
      }
    }
  }
}

// Item title
.page--content__item__title {
  flex: 0 0 40%;
  padding-right: 2rem;
}

// Item content
.page--content__item__content {
  flex: 0 0 50%;
}
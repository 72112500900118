// Bootstrap setup
@import '../assets/vendor/bootstrap/scss/functions';

// Fonts
@import 'fonts';

// Front setup
@import 'front/variables';

// Custom setup
@import 'user-variables';

// Bootstrap core
@import '../assets/vendor/bootstrap/scss/bootstrap';

/*----------------------------------------------------------------------
  * Front - Responsive Website Template version v2.9.0
  * Copyright 2018 Htmlstream
  * Licensed under Bootstrap Themes (https://themes.getbootstrap.com/licenses/)
------------------------------------------------------------------------*/

@import 'front/front';

// Pages
@import 'navigation';
@import 'page--content';
@import 'page--contact';

// Active links
.link-active {
  color: $primary !important;
}

// Custom core
@import 'user';
// Inter webfont
@font-face {
  font-family: 'inter';
  src: url('/static/fonts/Inter-Regular.woff2') format('woff2'),
  url('/static/fonts/Inter-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'inter';
  src: url('/static/fonts/Inter-Italic.woff2') format('woff2'),
  url('/static/fonts/Inter-Italic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'inter';
  src: url('/static/fonts/Inter-Medium.woff2') format('woff2'),
  url('/static/fonts/Inter-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'inter';
  src: url('/static/fonts/Inter-Bold.woff2') format('woff2'),
  url('/static/fonts/Inter-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}